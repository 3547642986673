<template>
  <div class="lb-search-block">
    <form class="relative lb-search-wrapper" @submit.prevent="goToSearchResults" role="search" id="search">
      <input type="text" class="lb-search-input" :placeholder="getLocaleString(searchPlaceholder)" v-model="rawSearch"/>
      <button type="submit" class="lb-search-button" :aria-label="$t('widgets.search.default-placeholder')">
        <Loading class="animate-spin h-5 w-5" v-if="loading"/>
        <LucideSearch class="search-icon" aria-hidden="true" v-else/>
      </button>

      <OnClickOutside
          class="absolute max-h-72 scroll-py-2 overflow-y-auto py-2 text-sm text-gray-800 z-10 left-0 right-0 w-full bg-white border border-t-0 rounded-lg"
          v-if="results?.length > 0" @trigger="results = []">
        <div class="text-sm text-gray-500 font-medium pl-2.5">{{ $t('widgets.search.instant-search-results') }}</div>
        <div v-for="item in results" :key="item.url" class="group">
          <NuxtLinkLocale :to="checkAndAddSlash(item.url)" class="flex space-x-2 hover:bg-gray-100 px-2.5 py-2">
            <NuxtImg
                :src="getImage(item.thumbnail)"
                :alt="item.modelSku"
                fit="cover"
                width="40"
                height="40"
                class="h-10 w-10 object-cover"
            />

            <div class="flex flex-col justify-center truncate">
              <div class="block truncate font-medium group-hover:text-secondary">
                {{ item.modelSku }} - {{ item.productTitle }}
              </div>
              <div class="text-gray-400" v-if=" item.variantCount > 1">{{ $t('widgets.search.n-option', item.variantCount, [item.variantCount]) }}</div>
            </div>
          </NuxtLinkLocale>
        </div>

        <p v-if="loading" class="p-4 text-sm text-gray-500">{{ $t('widgets.search.loading') }}</p>
        <p v-else-if="results?.length === 0 && !loading" class="p-4 text-sm text-gray-500">>{{ $t('widgets.search.no-result') }}</p>
      </OnClickOutside>
    </form>

    <RegionSelector class="!hidden lg:!flex" v-if="isRegionControlIsActive && isDesktop"/>
  </div>
</template>

<script setup>
import SearchIcon from "~/assets/icons/search.svg"
import Loading from "~/assets/icons/loading.svg"
import {MagnifyingGlassIcon} from "@heroicons/vue/20/solid/index.js";
import {OnClickOutside} from '@vueuse/components'

const storeConfig = useStoreConfig()
const {isRegionControlIsActive, baseConfig, showSearchRecommendations} = storeToRefs(storeConfig)

const {$event} = useNuxtApp()

const route = useRoute()
const loading = useState('searchLoading', () => false)
const results = ref()
const rawSearch = ref()

const {baseParams, items, queryStringKey, search} = usePagination()
const {isSearchPage} = useListing()
const {activeFilters} = useFilter()

const debounce = ref()
const {isDesktop} = useDevice()

const searchPlaceholder = computed(() => baseConfig.value?.search_placeholder);

async function searchQuery(value) {

  // Eğer veri yoksa, herhangi bir işlem yapmıyoruz
  if (!value) {
    return;
  }

  console.log('isSearchPage.value', isSearchPage.value)

  clearTimeout(debounce.value)
  results.value = []

  if (!isSearchPage.value) {
    search.value = value

    // Filtreleri ve verileri sıfırlıyoruz
    items.value = []
    activeFilters.value = {}
  }


  debounce.value = await setTimeout(() => instantSearch(value), 600)
}

function goToSearchResults() {

  if (isSearchPage.value) search.value = rawSearch.value

  if (!search.value) {
    return;
  }

  // Filtreleri ve verileri sıfırlıyoruz
  clearTimeout(debounce.value)
  items.value = []
  activeFilters.value = {}
  results.value = []
  loading.value = true

  if (!isSearchPage.value) {
    navigateTo({
      path: '/search',
      query: {
        ...route.query,
        q: search.value
      }
    })
  } else {
    // Eğer arama sayfasında ise "enter"a basıldığında sayfayı yeniden yüklettiriyoruz
    $event('reloadPageSearchPage', {page: 1, query: search.value})
  }

  loading.value = false
}

async function instantSearch(value) {
  if (!showSearchRecommendations.value) {
    return;
  }

  loading.value = true
  results.value = await $fetch(`/api/search`, {
    method: 'GET',
    query: {
      query: value
    },
  })
  loading.value = false;
}

watch(rawSearch, (newValue) => {
  searchQuery(newValue)
})

</script>

<style lang="scss">
.search-icon {
  @apply h-5 w-5 text-gray-300 mr-1;
}
</style>