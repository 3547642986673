<template>
  <button class="btn-hamburger" @click="openMobileMenu=true">
    <LucideMenu class="hamburger-icon mr-1" />
    <span v-if="label">{{label}}</span>
  </button>
</template>

<script setup>
const props = defineProps(['label'])
const openMobileMenu = useState('openMobileMenu')

</script>

<style lang="scss">
  .btn-hamburger {
    @apply flex justify-center items-center p-2 lg:h-auto;
  }

  .hamburger-icon {
    @apply h-5 w-5 sm:h-4 sm:w-4 fill-primary;
  }
</style>